import { Form } from '@unform/web';
import { useAuth } from '@/hooks/useAuth';
import { ButtonEnviar, Container, Header, Line } from './styles';
import { rememberMeStore } from '@/services/zustand/rememberme';
import { useRouter } from 'next/router';
import { InputLogin } from '@/components/global/InputLogin';
import InputAdornment from '@mui/material/InputAdornment';
import HttpsIcon from '@mui/icons-material/Https';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useState } from 'react';
import Logo from '../icons/logo';

export function Card() {
  const { handleLogin, formRef } = useAuth();
  const router = useRouter();
  const { email, rememberMe } = rememberMeStore();

  const [password, setPassord] = useState<any>()
  const [emailuser, setEmail] = useState<any>()

  return (
    <Container>
      <Line />
      <Header>
        <Logo />
        <h1>Macroplan SACP </h1>
        <p>Sistema de Apropriação de Custos aos Projetos</p>
      </Header>
      <Form ref={formRef} onSubmit={() => { }} initialData={{ email, remember: rememberMe }}>
        <div className="input_wrapper">
          {/* <InputLogin placeholder='Email' label='Login' name='email' /> */}
          <TextField
            sx={{ width: "80%" }}
            name='email'
            placeholder='Email'
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </div>
        <div className="input_wrapper">
          {/* <InputLogin placeholder='senha' label='Senha' name='password' type='password' /> */}
          <TextField
            sx={{ width: "80%" }}
            label="Password"
            placeholder='Password'
            name='password'
            onChange={(e) => setPassord(e.target.value)}

            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HttpsIcon />
                </InputAdornment>
              ),
            }}
            type='password'
            variant="standard"
          />

        </div>
        <div className="forgot_pass">
          {/* <a href="#"><p>Esqueci minha senha</p></a> */}
        </div>
        {/* <div className="checkbox_wrapper">
          <input name='save_login' type='checkbox' />
          <span>Mantenha-me conectado</span>
        </div> */}
        <ButtonEnviar type='button' onClick={() => handleLogin(emailuser, password)}>
          <p>Entrar</p>
        </ButtonEnviar>
        {/* <p>Primeiro acesso? <b onClick={() => router.push('/participe')} style={{ cursor: 'pointer' }}>Se cadastre!</b></p> */}
      </Form>
    </Container>
  );
}
