import styled from 'styled-components';

export const GlobalWrapperContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const GlobalContainer = styled.main`
  width: 100%;
  @media(max-width: 960px) {
    width: auto;
  }

  .border-bottom {
    width: 100%;
    height: 4px;
    background: black;
  }
`;
