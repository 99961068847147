import { persist } from 'zustand/middleware';
import { UseBoundStore, StoreApi, create } from 'zustand';

interface IRemember {
  email: string | null
  rememberMe: boolean | null
}

export const rememberMeStore: UseBoundStore<StoreApi<IRemember>> = create(
  persist(
    (set, _) => ({
      email: null,
      rememberMe: null,
    }),
    {
      name: 'remember',
    },
  ),
);
