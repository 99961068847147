import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import { Container, ContainerLogin, ContainerMap } from './styles';
import { Card } from './partials/Card';
import Icon from './svgs/icon';

const LoginContent: React.FC = () => {
  
  return (
    <Container>
      <ContainerLogin>
        <Card />
      </ContainerLogin>
    </Container>
  )
}

export default LoginContent;