import LoginContent from '@/components/login';
import { PublicLayout } from '@/components/global/Layout/Public';

export default function Home() {
  return ( 
    <PublicLayout>
      <LoginContent/>
    </PublicLayout>
   );
}
