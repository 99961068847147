import React from 'react';
import Head from 'next/head';
import { HeaderBar } from '../../Header';
import GlobalStyles from "@/styles/global";
import { dark } from "@/styles/themes/dark";
import { light } from "@/styles/themes/light";
import { ThemeProvider } from "styled-components";
import { useTheme } from '@/services/zustand/theme';
import { GlobalContainer, GlobalWrapperContainer } from './styles';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { FrameMotionTransition } from '../../FramerMotion';

interface IProps {
  children: React.ReactNode;
}

export const PublicLayout: React.FC<IProps> = ({ children }) => {
  const [isClient, setIsClient] = React.useState(false);
  const theme = useTheme(state => state.theme);
  const pathname = usePathname();
  const router = useRouter();

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  // React.useEffect(() => {
  //   if (pathname === '/login' && localStorage.getItem("JWT_USER")) {
  //     router.push('/');
  //   }
  // }, [pathname, router]);

  return (
    <>
      {isClient && (
        <ThemeProvider theme={theme === 'light' ? light : dark}>
          <Head>
            <title>Macroplan SACP</title>
            <meta name="description" content="criado por macroplan" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/favicon.ico" />
          </Head>
          <FrameMotionTransition>
            <GlobalStyles />
            <GlobalWrapperContainer>
              <GlobalContainer>
                  {children}
              </GlobalContainer>
            </GlobalWrapperContainer>
          </FrameMotionTransition>
        </ThemeProvider>
      )}
    </>
  );
};