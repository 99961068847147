import styled from "styled-components";

export const Container = styled.div`
    background: var(--secundary-branco, #fff);
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 400px;
    height:auto;
    
    form{
      display:flex;
      flex-direction:column;
      align-items:center;
      width:100%;
      height:84%;
      margin: 2vh 2vw 2vh 2vw;
      .input_wrapper{
        display:flex;
        flex-direction:column;
        align-items: center;
        width:100%;
        margin:3vh 1vw 2vh 1vw;
        span {
           color: var(--text-primary, #272930);
          font-size: 19px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-bottom: 4px;
         }
        /* input{
          z-index:1;
          background:none;
          width:80%;
          height:6vh;
          border-radius:4px;
          border:none;
          font-size:16px;
          padding:0vh 1vw 0vh 1vw;
          box-shadow: 0 0 5pt 0.5pt #bdb9b9;
          &:focus{
            outline:none;
            ~ .input_title{
              z-index:2;
              position:absolute;
              margin:-5vh 1vw 10vh 0vw;
              p{
                font-size:105%;
              }
            }
          }
        }
        input:not(:placeholder-shown)
          ~ .input_title{
            z-index:2;
              position:absolute;
              margin:-5vh 1vw 10vh 0vw;
              p{
                font-size:105%;
              }
          }
        .input_title{
          z-index:0;
          position:absolute;
          display:flex;
          align-items:center;
          width:22%;
          height:6%;
          margin: 0vh 0vw 0vh 0vw;
          transition:0.2s margin ease-in-out; 
          color:#757575;
        } */
      }
      .forgot_pass{
        width:80%;
        a{
          text-decoration: none;
          color:black;
        }
      }
      .checkbox_wrapper{
        display:flex;
        align-items:center;
        width:80%;
        margin-top:3vh;
        input {
          width: 20px;
          height: 20px;
        }
        span{
          margin-left:1vw;
        }
      }
    }
`;
export const Header = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    padding: 1vh 0vw 1vh 0vw;
    h1{
      color: #eb8a42;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      margin-top: 15px;
      line-height: 130%;
    }
    p {
      color: #1d1d1b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
`;

export const Line = styled.div`
  width: 100%;
  height: 4px;
  background: #eb8a42;
`;



export const ButtonEnviar = styled.button`
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  /* background: ${({ theme }) => theme.login.button.bg}; */
  background-color:#eb8a42;
  margin-top: 55px;
  width: 80%;
  align-self: center;
  p {
    color: var(--secundary-branco, #fff);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 200% */
  }

  &:hover {
    cursor: pointer;
  }
`;
