import styled from "styled-components";
import { rgba } from 'polished';

/* export const Container = styled.div`
  display: flex;
  justify-content:center;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.home.banner.banner_background};

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 5% 0;
  }
` */
export const Container = styled.div`
  display: flex;
  justify-content:center;
  height: 100vh;
  width: 100vw;
  overflow:hidden;
  background-color:${rgba('black', 0.5)};

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 5% 0;
  }
`

export const ContainerMap = styled.div`
  height: 800px;
  width: 66%;
  display: flex;
  align-items: center;
  position: relative;
  .map{
    height: 80%!important;
  }
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 820px) {
    display: none;
  }
`
export const ContainerLogin = styled.div`
  height:100vh;
  width:100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 100%;
  }

`